import React, { useEffect, useState } from 'react'
import IconButton from "../../../components/shared/buttons/IconButton";
import {
  Segment,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import _ from 'lodash'
import { connect } from 'react-redux';
import { capitalize } from '../../../utils/stringUtils'
import { areThereDuplicateFigures } from '../../../utils/projectPlanningUtils'

import {
  clearPfe,
  setFinancialDataModalOpen,
  startGetPfe,
  startSavePfe
} from '../../../store/actions/shared/professionalFigures';
import { startGetCutoffPM } from '../../../store/actions/core/administration/cutoff';
import PfeNextEditProductContainer from './PfeNextEditProductContainer';
import PfeNextEditServiceContainer from './PfeNextEditServiceContainer';
import { toast } from 'react-semantic-toasts'
import constants from '../../../utils/constants';
import Can from '../../../abilities/Can';
import { invalid } from 'moment';
import { history } from "../../../routers/AppRouters"

const FIELDS = {
  project_code: 'Project Code',
  currency_code: 'Currency',
  start_date: 'Start Date',
  end_date: 'End Date',
  status: 'Status',
  project_type: 'Project Type',
  offering_type: 'Offering Type',
  approval_date: 'Approval Date',
};


function PfeNextEditContainer(props) {
  const pfe_id = props.match.params.pfe_id

  useEffect(() => {
    props.getPfe(pfe_id)
    props.getCutoffPM()

    return () => {
      clearPfe()
    }
  }, [])


  const isFinancialDataComplete = (financialData, pfeDetails) => {
    const {
      revenues,
      billable_expenses,
      expenses,
      contingency_percentage,
      currency_risk_percentage,
    } = financialData

    const variables = [revenues];
    // AEP-4755: Modifichiamo il controllo per permettere le revenues a 0
    const hasNullValue = variables.some(variable => variable === null || variable === undefined);
    // bugfix 107
    if (contingency_percentage < 0) return true
    if (currency_risk_percentage < 0) return true

    if (hasNullValue) {
      return true;
    }

    return false
  }

  const invalidFinancialData = (financialData, pfeDetails) => {
    const {
      revenues,
      billable_expenses,
      cost,
      expenses,
      contingency_percentage,
      currency_risk_percentage,
      sg_percentage,
    } = financialData

    if (pfeDetails !== undefined && pfeDetails.revenues_actual !== undefined && revenues < pfeDetails.revenues_actual) return "Revenues"
    if (pfeDetails !== undefined && pfeDetails.billable_expenses_actual !== undefined && billable_expenses < pfeDetails.billable_expenses_actual) return "Billable Expenses"
    if (pfeDetails !== undefined && pfeDetails.expenses_actual !== undefined && expenses < pfeDetails.expenses_actual) return "Expenses"
    if (pfeDetails !== undefined && pfeDetails.costs_actual !== undefined && cost < pfeDetails.costs_actual) return "Costs" //TODO error message for user

    return ''
  }

  const areProfessionaFiguresComplete = (figures) => {
    const incompleteFigures = figures.filter(x => x.company === "" || x.level === "" || x.area === "" || x.sales_level === "" || (x.level === "EXT" && x.hourly_cost === 0))
    return incompleteFigures.length === 0
  }


  const onSave = (isConfirm) => {

    const onFailure = res => {
      var description = 'Error saving PFE';
      if (res.response !== undefined && (res.response.status === 406 || res.response.status === 403)) {
        description = res.response.data.error
      }
      toast({
        title: 'Error',
        description: description,
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
    };

    const onSuccess = res => {
      toast({
        title: 'Success',
        description: 'The PFE has been saved',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });


    }

    let body = null;
    if (props.pfe.type === "PROD") {
      body = {
        status: "confirmed",
        pfe_summary: props.costsAndRevenues
      }
    } else {
      if (isFinancialDataComplete(props.financialData)) {
        // console.log('isFinancialDataComplete - financialData', props.financialData);

        toast({
          title: 'Warning',
          description: 'Some data is missing in the Financial Data section. Please complete this section.',
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
        return;
      }

      if (isConfirm) {
        const invalidFinancialDataDescription = invalidFinancialData(props.financialData, props.pfeDetails)
        if (invalidFinancialDataDescription !== "") {
          toast({
            title: 'Warning',
            description: `${invalidFinancialDataDescription} cannot be lower than actual`,
            type: 'warning',
            icon: 'file',
            time: constants.TOAST_WARNING_TIMEOUT,
            animation: 'fly left',
          });
          return;
        }
      }

      if (areThereDuplicateFigures(props.professionalFigures, "pfe")) {
        toast({
          title: 'Warning',
          description: 'There are some professional figures with the same company, area, internal level and sales level. Please check and remove duplicates.',
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
        return;
      }

      body = {
        status: isConfirm ? 'confirmed' : 'draft',
        pfe_details: props.professionalFigures.map(figure => (
          figure.hours.map(h => ({
            ...figure,
            year: h.year,
            month: h.month,
            hours: h.hour,
            internal: !figure.external,
            sale_level: figure.sales_level,
            fromDB: undefined,
            cost_hour: figure.hourly_cost,
            hourly_cost: undefined,
            company_id: undefined,
            site_percentage_expense: null,
            cost_hour_expense: null,
            //BUG FIX UAT 52: mandare hourly_rate reale invece che 0, perche' 0 spacca lo storico del DB
            hourly_rate: figure.real_hourly_rate,
            real_hourly_rate: undefined
          }))
        )).flat(),
        pfe_summary: props.financialData
      }
    }
    props.startSavePfe(pfe_id, body, onFailure, onSuccess)
    history.push('/project-planning/PFE_next')
  }

  const isButtonDisabled = () => {
    if (!(props.pfe.status === "created" || props.pfe.status === "rejected" || props.pfe.status === "draft"))
      return true
    if (!areProfessionaFiguresComplete(props.professionalFigures)) {
      return true
    }
    return false;
  }

  useEffect(() => {
    const listener = (event) => {
      event.preventDefault();
      return "You haven't saved or confirmed the PFE, do you want to save the PFE? Otherwise some data may be lost."
    }
    window.addEventListener("beforeunload", listener);

    return () => {
      window.removeEventListener("beforeunload", listener)
    }
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 50px)' }}>
      <Dimmer active={props.loading} inverted>
        <Loader indeterminate inverted content="Loading" size="medium" />
      </Dimmer>

      <Can I="project_planning:Read" a="pfes_next">
        {props.pfe !== undefined &&
          <>
            <Segment style={{ height: 'calc(100vh - 50px)', maxWidth: '200px', display: 'flex', flexDirection: 'column', margin: "0" }} floated='left'>
              {Object.keys(FIELDS).map(key => (
                <div className="custom-form-field" key={key}>
                  <label>{FIELDS[key]}</label>
                  {key === "status" && <div>{capitalize(props.pfe[key])}</div>}
                  {key === "approval_date" && <div>{props.pfe[key] === null ? "Not Approved" : props.pfe[key]}</div>}
                  {key !== "status" && key !== "approval_date" && <div>{props.pfe[key]}</div>}
                </div>
              ))}

              <div style={{ marginTop: 'auto', display: 'flex', flexDirection: 'column' }}>
                {props.pfe.type === "TS" && <IconButton label='Financial Data' icon='chart bar' color='green' onClick={() => props.setFinancialDataModalOpen(true)} />}
                {props.pfe.type === "TS" && <IconButton label='Save Draft' icon='hdd outline' color='blue' onClick={() => onSave(false)} disabled={isButtonDisabled()} />}
                <IconButton label='Confirm PFE' icon='check circle outline' color='teal' onClick={() => onSave(true)} disabled={isButtonDisabled()} />
              </div>
            </Segment>

            {props.pfe.type === "PROD" && <PfeNextEditProductContainer pfe_id={pfe_id} />}
            {props.pfe.type === "TS" && <PfeNextEditServiceContainer pfe_id={pfe_id} />}
          </>
        }
      </Can>
    </div>
  )
}


const mapStateToProps = state => ({
  loading: state.professionalFiguresReducer.loadingPfe ||
    state.cutoffReducer.loading_pm,
  pfe: state.professionalFiguresReducer.pfe,

  costsAndRevenues: state.professionalFiguresReducer.costsAndRevenues,
  professionalFigures: state.professionalFiguresReducer.pfeProfessionalFigures,
  financialData: state.professionalFiguresReducer.financialData,
});

const mapDispatchToProps = dispatch => {
  return {
    getPfe: (pfeId) => dispatch(startGetPfe(pfeId)),
    startSavePfe: (pfeId, body, onFailure, onSuccess) => dispatch(startSavePfe(pfeId, body, onFailure, onSuccess)),
    getCutoffPM: () => dispatch(startGetCutoffPM()),
    setFinancialDataModalOpen: (isOpen) => dispatch(setFinancialDataModalOpen(isOpen)),
    clearPfe: () => dispatch(clearPfe())
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PfeNextEditContainer);
